angular.module('llax')
    .controller('MessagingController',
        function($rootScope, $scope, $http, $log, $translate, $timeout, ChannelService, CountMessageResource, HttpHeader, MessagingResource, QueryMessageResource, uiGridConstants) {

            $scope.messagingStatus = ['DEFERRED', 'PENDING', 'BLOCKED', 'SENDING', 'DELIVERED', 'SUCCESS', 'EXCEPTION', 'WARNING', 'FATAL',
                                      'RECEIVED', 'IMPORTING', 'IMPORTED', 'PENDING_PROCESSING', 'PROCESSING', 'PROCESSED' ,'ERROR'];

            $scope.createColumnDefs = function(channelId) {
                return [{
                    field: 'direction',
                    displayName: $translate.instant('MESSAGING.DIRECTION'),
                    width: '*',
                    headerCellClass: 'text-center',
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: [ { value: 'INBOUND', label: 'INBOUND' }, { value: 'OUTBOUND', label: 'OUTBOUND' } ]
                    }
                }, {
                    field: 'createdAt',
                    displayName: $translate.instant('MESSAGING.CREATED_AT'),
                    headerTooltip: true,
                    cellFilter: "date:'medium'",
                    headerCellClass: 'text-center',
                    filterHeaderTemplate: '<div class="ui-grid-filter-container" style="padding-left: 2px;padding-right: 15px;" data-ng-init="colFilter={}">' +
                                            '<div style="margin-bottom:3px">'+
                                                '<span class="syncons syncons-double-arrows-right"></span>' +
                                                '<input type="text" name="createdAt-before-{{'+ channelId +'}}" style="width: 88%;height: 20px;"' +
                                                'data-ng-model="colFilter.createdAfter" data-ng-change="colFilter.createdBefore=null;grid.appScope.customFilterChanges('+ channelId +',colFilter)"' +
                                                'data-lax-datetime-picker data-lax-datepicker-grid/>' +
                                                '<span ng-show="colFilter.createdAfter" ng-click="colFilter.createdAfter=null;grid.appScope.customFilterChanges('+ channelId +',colFilter)"' +
                                                    'style="position: absolute;marging-left: -2px;"><i class="ui-grid-icon-cancel" ui-grid-one-bind-aria-label="aria.removeFilter" aria-label="Remove Filter">&nbsp;</i></span>' +
                                            '</div>'+
                                            '<div>'+
                                                '<span class="syncons syncons-double-arrows-left"></span>' +
                                                '<input type="text" name="createdAt-after-{{'+ channelId +'}}" style="width: 88%;height: 20px;"' +
                                                'data-ng-model="colFilter.createdBefore" min-date="colFilter.createdAfter" data-ng-change="grid.appScope.customFilterChanges('+ channelId +',colFilter)"' +
                                                'data-lax-datetime-picker data-lax-datepicker-grid/>' +
                                                '<span ng-show="colFilter.createdBefore" ng-click="colFilter.createdBefore=null;grid.appScope.customFilterChanges('+ channelId +',colFilter)"' +
                                                    'style="position: absolute;marging-left: -2px;"><i class="ui-grid-icon-cancel" ui-grid-one-bind-aria-label="aria.removeFilter" aria-label="Remove Filter">&nbsp;</i></span>' +
                                            '</div>' +
                                        '</div>',
                    width: '*'
                }, {
                    field: 'changedAt',
                    displayName: $translate.instant('MESSAGING.CHANGED_AT'),
                    headerTooltip: true,
                    cellTooltip: true,
                    cellFilter: "date:'medium'",
                    width: '*',
                    headerCellClass: 'text-center',
                    enableFiltering: false
                }, {
                    field: 'contentType',
                    displayName: $translate.instant('MESSAGING.CONTENT_TYPE'),
                    headerTooltip: true,
                    cellTooltip: true,
                    width: '*',
                    headerCellClass: 'text-center',
                    enableFiltering: false
                }, {
                    field: 'path',
                    displayName: $translate.instant('MESSAGING.PATH'),
                    headerTooltip: true,
                    cellTooltip: true,
                    width: '*',
                    headerCellClass: 'text-center',
                    enableFiltering: false
                }, {
                    field: 'status',
                    displayName: $translate.instant('STATUS'),
                    headerTooltip: true,
                    width: '*',
                    headerCellClass: 'text-center',
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: _.map($scope.messagingStatus, function(val) {
                            return { value: val, label: val };
                        })
                    },
                    cellTemplate: '<div class="ui-grid-cell-contents text-center">' +
                                '<span class="label" data-ng-class="grid.appScope.getStatusLabel(row.entity.status)">{{grid.appScope.translateStatus(row.entity.status)}}</span>' +
                            '</div>'
                }, {
                    field: 'actions',
                    displayName: $translate.instant('ACTIONS'),
                    headerTooltip: true,
                    width: '*',
                    headerCellClass: 'text-center',
                    cellClass: 'text-right',
                    enableColumnMenu: false,
                    enableColumnResizing: false,
                    enableFiltering: false,
                    enableHiding: false,
                    enableSorting: false,
                    pinnedRight: true,
                    resizable: false,
                    cellTemplate: '' +
                        '<div class="btn-group">\n' +
                        '    <a class="btn btn-xs btn-cell"\n' +
                        '      title="{{\'MESSAGING.IMPORTED_ITEMS\' | translate}}"\n' +
                        '      data-ng-show="row.entity.status == \'IMPORTED\'"\n' +
                        '      data-ng-href="/browse?tags=inboundMessage:{{row.entity.assetKey}}"\n' +
                        '      target="_self">\n' +
                        '        <span class="syncons syncons-search"></span>\n' +
                        '    </a>\n' +
                        '    <btn class="btn btn-xs btn-cell"\n' +
                        '      title="{{\'MESSAGING.DETAILS\' | translate}}"\n' +
                        '      data-ng-click="grid.appScope.showMessagingDetails(row.entity)">\n' +
                        '        <span class="syncons syncons-item-detail"></span>\n' +
                        '    </btn>\n' +
                        '    <a class="btn btn-xs btn-cell"\n' +
                        '      title="{{\'MESSAGING.DOWNLOAD_MESSAGE\' | translate}}"\n' +
                        '      data-ng-href="{{grid.appScope.downloadMessage(row.entity.id)}}"\n' +
                        '      target="_self">\n' +
                        '        <span class="syncons syncons-download"></span>\n' +
                        '    </a>\n' +
                        '</div>'
                }];
            };

            $scope.createChannelMessagesGridOptions = function (channelId) {
                return {
                    data: 'channels['+channelId+'].messages',
                    enableColumnMenus: false,
                    enableColumnMoving : true,
                    enableColumnResize: true,
                    enableFiltering: true,
                    enableGridMenu: true,
                    enableRowSelection: false,
                    infiniteScrollDown: true,
                    useExternalFiltering: true,
                    rowHeight: 34,
                    columnDefs: $scope.createColumnDefs(channelId),
                    onRegisterApi: function(gridApi) {
                        $scope.gridApi = gridApi;
                        gridApi.infiniteScroll.on.needLoadMoreData($scope, function(){
                            $scope.loadMoreMessages(channelId);
                        });
                        $timeout(function() {
                            $scope.gridApi.core.handleWindowResize();
                        });
                        gridApi.core.on.filterChanged($scope, function() {
                                var grid = this.grid;
                                var channel = grid.appScope.channels[channelId];
                                if (channel) {
                                    var params = $scope.additionalQueryParams[channel.id];
                                    var filterColumns = _.filter(grid.columns, function(col) {
                                        return !_.isEmpty(col.filter);
                                    });
                                    _.forEach(filterColumns, function(fCol) {
                                        if (fCol.filter.term) {
                                            params[fCol.field] = fCol.filter.term;
                                        } else {
                                            delete params[fCol.field];
                                        }
                                    });
                                    $scope.reloadMessages(channel, true);
                                }
                            }
                        );
                    }
                };
            };

            $scope.channelMessagesGridOptionsMap = {};
            $scope.getChannelMessagesGridOptions = function(id) {
                $scope.channelMessagesGridOptionsMap[id] = angular.copy($scope.createChannelMessagesGridOptions(id));
            };

            $scope.customFilterChanges = function(channelId, filter) {
                var channel = $scope.gridApi.grid.appScope.channels[channelId];
                var params = $scope.additionalQueryParams[channel.id];
                if (filter.createdBefore && angular.isDate(filter.createdBefore)) {
                    params.createdBefore = filter.createdBefore;
                } else {
                    delete params.createdBefore;
                }
                if (filter.createdAfter && angular.isDate(filter.createdAfter)) {
                    params.createdAfter = filter.createdAfter;
                } else {
                    delete params.createdAfter;
                }
                $scope.reloadMessages(channel, true);
            };

            $scope.translateStatus = function(status) {
                status = $rootScope.translateValue('MESSAGING.STATUS.', status);
                return status;
            };

            $scope.gridOptionsMap = {};

            ChannelService.register(ChannelService.MESSAGING_ASSET_CHANGED_EVENT);

            $scope.$on('$destroy', function() {
                ChannelService.unregister(ChannelService.MESSAGING_ASSET_CHANGED_EVENT);
            });

            $scope.$on('channelMessageReceived', function(event, eventData) {
                if (eventData.event === ChannelService.MESSAGING_ASSET_CHANGED_EVENT) {
                    $log.debug("Messaging Asset Changed", eventData.data.typeOfChange, eventData.data.messagingAsset);

                    var messagingAsset = eventData.data.messagingAsset;
                    var channelId = messagingAsset.channelId;
                    var channel = $scope.channels[channelId];
                    if (!channel) {
                        $log.debug("Unknown channel:", channelId);
                        return;
                    }
                    if (!channel.showDetails.messages) {
                        $log.debug("Messages not shown for channel:", channelId);
                        return;
                    }
                    if (eventData.data.typeOfChange == 'uploaded') {
                        // FIXME: Add uploaded message
                        $scope.reloadMessages(channel, false);
                    } else {
                        // FIXME: Find message and update it
                        $scope.reloadMessages(channel, false);
                    }

                }
            });

            function scopeVarForCreatorData(creatorId, creatorType) {
                return 'messages_' + creatorId + '_' + creatorType;
            }

            $scope.getGridOptions = function(creatorId, creatorType) {
                var scopeVariable = scopeVarForCreatorData(creatorId, creatorType);
                $scope[scopeVariable] = [];

                var gridOptions = {
                    data: $scope[scopeVariable],
                    // FIXME: Actually create the columnDefs instead using the "default" which tries to determine them by the data!
                    // columnDefs: $scope.columnDefs,
                    enableColumnMenus: false,
                    enableColumnMoving : true,
                    enableColumnResize: true,
                    enableGridMenu: true,
                    enableRowSelection: false,
                    rowHeight: 34
                };
                $scope.gridOptionsMap[creatorId] = gridOptions;
            };

            $scope.loadMessagesForCreator = function(creatorId, creatorType, channel) {
                var scopeVariable = scopeVarForCreatorData(creatorId, creatorType);
                MessagingResource.query({
                        creatorId: creatorId,
                        creatorType: creatorType,
                        channelId: channel.id
                    }, {},
                    function(messagingAssets) {
                        // FIXME: As long as we do not have proper grid columns, we want to at least see dates as actual dates!
                        _.forEach(messagingAssets, function(messagingAsset) {
                            messagingAsset.createdAt = new Date(messagingAsset.createdAt).toISOString();
                            messagingAsset.changedAt = new Date(messagingAsset.changedAt).toISOString();
                        });
                        angular.extend($scope[scopeVariable], messagingAssets);
                    },
                    function(response) {
                        $scope.status = response.status;
                    });
            };

            $scope.countMessages = function(channel) {
                var queryParams = {
                    channelId: channel.id,
                };

                if (!_.isEmpty($scope.additionalQueryParams[channel.id])) {
                    angular.extend(queryParams, $scope.additionalQueryParams[channel.id]);
                }

                if (!channel.countingInProgress) {
                    channel.countingInProgress = true;

                    CountMessageResource.get(queryParams, {},
                        function (response) {
                            channel.countMessage = $rootScope.translate("MESSAGING.COUNT", "Number of messages: {{response.numberOfMessages}}", {'messageCount':response.numberOfMessages});
                            channel.needsCount = false;
                            channel.countingInProgress = false;
                        },
                        function (response) {
                            channel.countMessage = $rootScope.getErrorMessage(response);
                            channel.needsCount = true;
                            channel.countingInProgress = false;
                        }
                    );
                }
            };

            $scope.reloadMessages = function(channel, doCount) {
                if (!channel.loadingMessages) {
                    channel.cursor = null;
                    channel.loadingMessages = true;
                    config = {append:false};
                    $scope.fetchMessagesForChannel(channel, config);

                    if (doCount) {
                        $scope.countMessages(channel);
                    } else {
                        channel.needsCount = true;
                    }
                }
            };

            $scope.loadMoreMessages = function(channelId, config, channel) {
                channel = channel || $scope.gridApi.grid.appScope.channels[channelId];
                if (!channel.loadingMessages && !channel.searchComplete) {
                    channel.loadingMessages = true;
                    config = config || {append:true};
                    $scope.fetchMessagesForChannel(channel, config);
                    if (channel.cursor == null) {
                        $scope.countMessages(channel);
                    }
                }
            };

            $scope.fetchMessagesForChannel = function(channel, config) {
                var queryParams = {
                    channelId: channel.id,
                    limit: 20
                };

                if (!_.isEmpty($scope.additionalQueryParams[channel.id])) {
                    angular.extend(queryParams, $scope.additionalQueryParams[channel.id]);
                }

                QueryMessageResource.createCursorAwareResource(channel.cursor).query(queryParams,
                    function(messagingResponse, headers) {
                        var cursor = headers(HttpHeader.MESSAGE_CURSOR);
                        if (cursor) {
                            channel.cursor = cursor;
                        }
                        if (messagingResponse.length === 0) {
                            channel.searchComplete = true;
                        }
                        _.forEach(messagingResponse, function(message) {
                            message.translatedStatus = $rootScope.translateValue('MESSAGING.STATUS.', message.status);
                            message.translatedCreatorType = $rootScope.translateValue('MESSAGING.CREATOR_TYPE.', message.creatorType);
                        });
                        if (config.append) {
                            channel.messages = channel.messages.concat(messagingResponse);
                        } else {
                            channel.messages = messagingResponse;
                        }
                        if ($scope.gridApi) {
                            $scope.gridApi.infiniteScroll.dataLoaded(false, true);
                        }
                        channel.loadingMessages = false;
                    }, function(response) {
                        if ($scope.gridApi) {
                            $scope.gridApi.infiniteScroll.dataLoaded(false, true);
                        }
                        $scope.status = response.status;
                        channel.loadingMessages = false;
                    }
                );
            };

            $scope.downloadMessage = function(messagingAssetId) {
                return lax_rest_url('messaging/messagingassets/') + messagingAssetId + '?download=true';
            };

            $scope.additionalQueryParams = {};

            $scope.toggleShowMessages = function(channel) {
                channel.cursor = null;
                $scope.additionalQueryParams[channel.id] = {};
                channel.showDetails.messages = !channel.showDetails.messages;
                if (channel.showDetails.messages) {
                    $scope.loadMoreMessages(channel.id, { append: false }, channel);
                }
            };

        }
    );
